<script>
  import { get, call } from 'vuex-pathify'
  import CustomizeCard from '@/components/customize/CustomizeCard'
  import CustomizeVariantCard from '@/components/customize/CustomizeVariantCard'
  import OrderSummary from '@/components/checkout/summary/OrderSummary'
  import AddressSummary from '@/components/checkout/summary/AddressSummary'
  import ChoicePlusCartItem from '@/components/checkout/ChoicePlusCartItem'
  import UpgradeConfirmation from '@/components/account/UpgradeConfirmation'
  import UpgradeSuccess from '@/components/account/UpgradeSuccess'
  import CheckoutErrorModal from '@/components/checkout/CheckoutErrorModal'
  import screen from '@/helpers/screen'
  import SoldOutItems from '@/components/checkout/SoldOutItems'
  import { ChoiceAutoSelectModalTypes } from '@/store/customize'
  import HelpMessaging from '@/components/app/HelpMessaging.vue'

  export default {
    components: {
      CustomizeCard,
      CustomizeVariantCard,
      ChoicePlusCartItem,
      UpgradeConfirmation,
      UpgradeSuccess,
      OrderSummary,
      AddressSummary,
      CheckoutErrorModal,
      SoldOutItems,
      HelpMessaging,
    },
    data() {
      return {
        ChoiceAutoSelectModalTypes,
        submitting: false,
        confirmed: false,
        upgradeSuccessfulVisible: false,
        upgradeSuccessfulTimeout: null,
        response: null,
        confirmationModalOpen: false,
      }
    },
    computed: {
      screen,
      ...get('feature', ['flags']),
      ...get('config', ['marketplaceEnabled', 'currentBox']),
      ...get('general', ['navigating']),
      ...get('account', ['findShipmentByOrderId']),
      ...get('customize', [
        'chosenVariants',
        'hasMoreChoicesComing',
        'filteredSelectedBoxVariants',
        'filteredSelectedBoxUpsellVariants',
        'isSelectedSubscriptionQuarterly',
        'selectedSubscription',
        'filteredSelectedPurchasedChoicePlusVariants',
        'isSelectedSubscriptionCustomizable',
        'choiceChooseForMeVariant',
        'savedChooseForMeVariantIds',
        'isMysteryVariant',
      ]),
      ...get('choicePlusCart', [
        'cartQuantity',
        'nonSoldOutLineItems',
        'soldOutLineItems',
        'placingOrder',
        'calculatingLineItems',
        'errors',
        'subtotal',
      ]),
      ...get('prismic', ['customizeReviewPageData']),
      nextShipDateMessage() {
        return this.customizeReviewPageData
          ? this.customizeReviewPageData.find((data) => data.type === 'next_shipping_date')
            .description_copy
          : ''
      },
      buttonCopy() {
        return this.cartQuantity > 0 ? 'Confirm Choices + Submit Order' : 'Confirm'
      },
      subscriptionId() {
        return this.$route.params.subscriptionId
      },
      soldOutVariants() {
        return this.soldOutLineItems?.map(({ variant }) => variant)
      },
      orderId() {
        return this.$route.params.orderId
      },
      isReviewAndConfirm() {
        return this.isSelectedSubscriptionCustomizable && !this.orderId
      },
      shipment() {
        return this.orderId ? this.findShipmentByOrderId(this.orderId) : undefined
      },
      shipmentTrackingUrl() {
        return this.orderId ? this.shipment?.trackingUrl : ''
      },
      isShipmentCurrentBox() {
        return this.orderId ? this.shipment?.boxId === this.currentBox?.id : false
      },
      addressToDisplay() {
        return this.orderId ? this.shipment?.address : this.selectedSubscription?.address
      },
      variantsToDisplay() {
        return this.orderId
          ? this.shipment?.lineItems.map((lineItem) => lineItem.variant)
          : this.chosenVariants
      },
      isPurchasedChoicePlusItemsVisible() {
        const purchasedChoicePlus = this.filteredSelectedPurchasedChoicePlusVariants?.length
        if (this.orderId) {
          return this.isShipmentCurrentBox && purchasedChoicePlus
        }
        return purchasedChoicePlus
      },
      isChooseForMeEnabled() {
        return this.flags['choose-for-me'] ?? false
      },
      newMemberMarketIsEnabled() {
        return this.flags['new-member-market'] ?? false
      },
    },
    watch: {
      confirmed() {
        clearTimeout(this.upgradeSuccessfulTimeout)
        if (this.confirmed) {
          this.confirmationModalOpen = false
          this.upgradeSuccessfulVisible = true
          this.upgradeSuccessfulTimeout = setTimeout(() => {
            this.upgradeSuccessfulVisible = false
            this.$navigate({
              name: 'CustomizeSubscription',
              params: { ...this.$route.params, subscriptionId: this.response.id },
            })
          }, 2000)
        } else {
          this.upgradeSuccessfulVisible = false
        }
      },
      cartQuantity() {
        if (this.cartQuantity) {
          this.reviewOrder()
        }
      },
    },
    created() {
      // preview on page load, regardless of existing previews, to get the shipping price
      this.clearRemovedLineItems()
      if (this.cartQuantity) {
        this.initCart()
      }
      // get purchased choice plus products
      const subscriptionReference = this.selectedSubscription?.reference
      this.fetchPurchasedChoicePlusVariants({ subscriptionReference })
    },
    methods: {
      ...call('subscribe', ['upgradeSubscription']),
      ...call('customize', [
        'load',
        'fetchPurchasedChoicePlusVariants',
        'setChoiceAutoSelectModalVisible',
      ]),
      ...call('choicePlusCart', [
        'initCart',
        'reviewOrder',
        'placeOrder',
        'removeCartItem',
        'toggleRemoveItem',
        'clearErrors',
        'clearSoldOutItemsFromCart',
        'clearRemovedLineItems',
      ]),
      async submit() {
        if (this.cartQuantity) {
          const response = await this.placeOrder({ subscriptionId: this.subscriptionId })
          if (response?.success) {
            this.$navigate({
              name: 'CustomizeChoicePlusThankYou',
              params: { subscriptionId: this.subscriptionId, ...this.$route.params },
            })
          }
        } else if (this.$route.params.subscribe && this.newMemberMarketIsEnabled) {
          this.$navigate({ name: 'SubscribeWelcome' })
        } else if (this.marketplaceEnabled) {
          this.$navigate({ name: 'Market' })
        } else {
          this.$navigate({ name: 'Home' })
        }
      },
      navigateToCustomize(choice) {
        this.$navigate({
          name: 'CustomizeChoice',
          params: {
            ...this.$route.params,
            choice,
          },
        })
      },
      navigateToDetails(variantId) {
        const detailsRouteName =
          this.$route.name === 'CustomizeOrderSummary'
            ? 'CustomizeOrderSummaryDetails'
            : 'CustomizeSummaryDetails'
        this.$navigate({
          name: detailsRouteName,
          params: {
            ...this.$route.params,
            variantId: variantId,
            boxId: this.shipment?.boxId,
          },
        })
      },
      upgrade() {
        this.confirmationModalOpen = true
      },
      async upgradeImmediately() {
        this.submitting = true
        try {
          this.response = await this.upgradeSubscription({
            subscriptionId: this.$route.params.subscriptionId,
          })
          await this.load()
          this.confirmed = true
        } catch (error) {
          this.$store.set('choicePlusCart/errors', error.data)
        } finally {
          this.submitting = false
        }
      },
      dismiss() {
        this.confirmationModalOpen = false
      },
      isVariantDataComplete(variant) {
        if (this.isReviewAndConfirm) {
          // allow undefined variants to show 'Select a Product' option
          // when user skips/doesn't finish selecting choices
          return true
        } else {
          return variant?.previewCardImages?.[0] && variant?.brand && variant?.productTitle
        }
      },
      isChooseForMeVariant(variant, choiceNumber) {
        const chooseForMeVariantSaved = this.savedChooseForMeVariantIds.includes(
          this.choiceChooseForMeVariant(choiceNumber).id
        )
        return (
          this.isReviewAndConfirm &&
          this.isChooseForMeEnabled &&
          !variant &&
          chooseForMeVariantSaved
        )
      },
      handleCustomizeCardDetails(variant, choiceNumber) {
        if (this.isChooseForMeVariant(variant, choiceNumber)) {
          this.setChoiceAutoSelectModalVisible({
            modalVisible: true,
            choiceAutoSelectModalType: ChoiceAutoSelectModalTypes.CHOOSE_FOR_ME,
          })
        } else if (this.isMysteryVariant(variant)) {
          this.setChoiceAutoSelectModalVisible({
            modalVisible: true,
            choiceAutoSelectModalType: ChoiceAutoSelectModalTypes.MYSTERY,
          })
        } else {
          this.navigateToDetails(variant.id)
        }
      },
    },
  }
</script>

<template>
  <div>
    <Portal to="modal">
      <CheckoutErrorModal
        v-if="errors"
        store-type="choicePlus"
        :errors="errors"
        @clearErrors="clearErrors"
      />
    </Portal>
    <div v-if="$route.name === 'CustomizeSummaryDetails' && !screen.md" key="details">
      <RouterView />
    </div>
    <div v-else key="main" class="mb-10">
      <!-- Mobile Order Summary -->
      <BaseDivider
        v-if="nonSoldOutLineItems.length > 0 && !screen.lg"
        :max-width="screen.sm ? 'sm' : undefined"
        color="black"
        :weight="1"
      />
      <BaseContainer
        v-if="nonSoldOutLineItems.length > 0 && !screen.lg"
        max-width="sm"
        class="my-6"
      >
        <BaseAccordion expanded icon="minus">
          <template v-slot:buttonLabel>
            <span class="font-heading font-medium text-xxl text-black capitalize">
              Order Summary
            </span>
          </template>
          <OrderSummary type="choicePlus" class="mt-5" />
        </BaseAccordion>

        <BaseDivider color="dawn-dk2" class="my-3" />
        <div class="text-3xs text-center">
          <PrismicRichText v-if="nextShipDateMessage" :field="nextShipDateMessage" />
          <br /><br />
          <p>
            By placing your order, you agree to Alltrue’s
            <BaseLinkStyled :to="{ name: 'PagePrivacy' }" target="_blank" inline>
              privacy notice
            </BaseLinkStyled>
            and
            <BaseLinkStyled :to="{ name: 'PageTermsOfUse' }" target="_blank" inline>
              conditions of use.
            </BaseLinkStyled>
          </p>
        </div>
      </BaseContainer>

      <BaseDivider
        v-if="!screen.lg"
        :max-width="screen.sm ? 'sm' : undefined"
        color="black"
        :weight="1"
      />

      <BaseContainer v-if="!screen.lg" max-width="sm" class="my-6">
        <AddressSummary
          v-if="addressToDisplay"
          :address="addressToDisplay"
          :tracking-url="shipmentTrackingUrl"
        />
      </BaseContainer>

      <BaseDivider
        v-if="!screen.lg"
        :max-width="screen.sm ? 'sm' : undefined"
        color="black"
        :weight="1"
      />

      <div :class="{ 'review-container mx-auto': screen.lg }">
        <!-- There isn't a clean way to line up the divider to text within BaseContainer without big layout changes -->
        <!-- 1. Use a wrapper to contain width 2. subtract BaseContainer left padding 3. align right  -->
        <div v-if="screen.lg" class="ml-auto mr-0" style="max-width: 908px">
          <BaseDivider class="mb-25px" />
        </div>

        <div class="md:flex md:items-start md:justify-center">
          <div class="md:w-140">
            <!-- Review Box Choices -->
            <BaseContainer max-width="sm" class="my-8 sm:my-6 lg:mt-0">
              <h3 class="font-heading font-medium text-xxl mb-5"
                >{{ isReviewAndConfirm ? 'Review' : '' }} Box Choices</h3
              >
              <div class="text-left grid grid-cols-1">
                <ul>
                  <li
                    v-for="(variant, choiceIndex) in variantsToDisplay"
                    :key="choiceIndex"
                    class="mb-4"
                  >
                    <CustomizeCard
                      v-if="
                        isVariantDataComplete(variant) ||
                        isChooseForMeVariant(variant, choiceIndex + 1)
                      "
                      :variant="variant"
                      :choice-step="choiceIndex + 1"
                      :cta="isReviewAndConfirm ? 'Edit' : 'View'"
                      layout="horizontal"
                      has-bottom-border
                      @edit="navigateToCustomize(choiceIndex + 1)"
                      @details="handleCustomizeCardDetails(variant, choiceIndex + 1)"
                    />
                  </li>
                </ul>
              </div>
              <div
                v-if="isReviewAndConfirm && hasMoreChoicesComing"
                class="mt-6 text-center text-xs"
              >
                More Box Choices<br />
                Coming Soon!
              </div>
              <!-- Upgrade to annual for quarterly members -->
              <template v-if="isSelectedSubscriptionQuarterly">
                <button
                  type="button"
                  class="px-3 mt-3 w-full rounded-full bg-white lg:bg-transparent h-15 max-w-100 flex items-center shadow-sm lg:shadow-none"
                  @click="upgrade"
                >
                  <BaseButtonPill color="summer" height="tall" type="display"
                    >Upgrade</BaseButtonPill
                  >
                  <span class="ml-4 w-2/3 font-medium text-2xs">
                    Upgrade to an Annual Membership to access more box customizations.
                  </span>
                </button>
                <BaseDivider color="dawn-dk2" class="my-3 hidden lg:block" />
              </template>
            </BaseContainer>

            <template v-if="isReviewAndConfirm">
              <!-- Review Choice+ Items -->
              <BaseDivider
                v-if="nonSoldOutLineItems.length > 0 && !screen.md"
                color="black"
                :weight="1"
              />
              <BaseContainer v-if="nonSoldOutLineItems.length > 0" max-width="sm" class="my-6">
                <h3 class="font-heading font-medium text-xxl">Review Choice+ Items</h3>
                <ul>
                  <li
                    v-for="(lineItem, lineItemIndex) in nonSoldOutLineItems"
                    :key="lineItemIndex"
                    class="border-b border-dawn-dk4"
                  >
                    <ChoicePlusCartItem
                      :line-item="lineItem"
                      @details="navigateToDetails(lineItem.variant.id)"
                      @toggleRemoveItem="toggleRemoveItem($event)"
                      @removeCartItem="removeCartItem($event)"
                    />
                  </li>
                </ul>
                <!----------- Sold out cart items ----------->
                <div
                  v-if="soldOutLineItems.length > 0"
                  :class="`${nonSoldOutLineItems.length > 0 ? 'border-t border-gray-active' : ''}`"
                >
                  <div class="my-4 flex text-xs leading-snug">
                    <div class="mr-2 flex-shrink-0 text-green">
                      <BaseIcon :size="6">
                        <IconInfo />
                      </BaseIcon>
                    </div>
                    <div class="mt-1">
                      Sold out items have been removed from your order.
                      <BaseLinkStyled @click="clearSoldOutItemsFromCart"> Dismiss</BaseLinkStyled>
                    </div>
                  </div>
                  <SoldOutItems type="choicePlus" :variants="soldOutVariants" />
                </div>
              </BaseContainer>
            </template>

            <!----------- Purchased Choice+ Items ----------->
            <BaseContainer v-if="isPurchasedChoicePlusItemsVisible" max-width="sm" class="my-6">
              <BaseAccordion icon="minus">
                <template v-slot:buttonLabel>
                  <span class="font-heading font-medium text-xxl text-black capitalize">
                    Purchased Choice+ Items
                  </span>
                </template>
                <ul>
                  <li
                    v-for="(variant, variantIndex) in filteredSelectedPurchasedChoicePlusVariants"
                    :key="variantIndex"
                  >
                    <ChoicePlusCartItem
                      :line-item="{ variant, removed: false, quantity: 1 }"
                      is-purchased
                      @details="navigateToDetails(variant.id)"
                    />
                  </li>
                </ul>
              </BaseAccordion>
            </BaseContainer>
            <BaseDivider v-if="!screen.md" color="black" :weight="1" />
          </div>
          <!-- Desktop Summary -->
          <template v-if="screen.lg">
            <div class="max-w-90 w-full ml-6 lg:mt-4px">
              <!-- Desktop Order Summary -->
              <template v-if="isReviewAndConfirm">
                <div v-if="nonSoldOutLineItems.length > 0" class="border-1 border-black pt-6 p-4">
                  <h4 class="font-heading font-medium text-xl">Order Summary</h4>
                  <OrderSummary type="choicePlus" class="mt-5" />
                  <div class="text-3xs text-center mb-5">
                    <PrismicRichText v-if="nextShipDateMessage" :field="nextShipDateMessage" />
                    <br /><br />
                    <p class="mx-7"
                      >By placing your order, you agree to Alltrue’s
                      <BaseLinkStyled :to="{ name: 'PagePrivacy' }" target="_blank" inline>
                        privacy notice
                      </BaseLinkStyled>
                      and
                      <BaseLinkStyled :to="{ name: 'PageTermsOfUse' }" target="_blank" inline>
                        conditions of use.
                      </BaseLinkStyled>
                    </p>
                  </div>
                  <BaseButton
                    color="primary-alt"
                    :disabled="placingOrder || calculatingLineItems"
                    @click="submit"
                    >{{ buttonCopy }}</BaseButton
                  >
                </div>

                <BaseButton
                  v-else
                  color="primary-alt"
                  :disabled="placingOrder || calculatingLineItems"
                  @click="submit"
                >
                  {{ buttonCopy }}
                </BaseButton>
              </template>

              <AddressSummary
                v-if="addressToDisplay"
                :address="addressToDisplay"
                :tracking-url="shipmentTrackingUrl"
              />
              <div class="text-center mt-10px text-xs leading-close">
                <HelpMessaging />
              </div>
            </div>
          </template>
        </div>
      </div>

      <template v-if="isReviewAndConfirm">
        <BaseDivider v-if="screen.md" color="black" :weight="1" class="my-8" />

        <!-- Choice+ Upsell Cards -->
        <BaseContainer max-width="md">
          <div class="my-6">
            <h3 class="font-heading font-semibold text-xxl text-center"> Get More with Choice+ </h3>
            <h4 class="text-xs text-center">
              Try Choice+ and get more products at amazing prices.
            </h4>
          </div>
          <ul class="grid grid-cols-2 md:grid-cols-4 gap-x-3 lg:gap-x-4 gap-y-8 lg:gap-y-6">
            <li
              v-for="variant in filteredSelectedBoxVariants"
              :key="variant.id"
              class="w-45 max-w-full"
            >
              <CustomizeVariantCard
                :variant="variant"
                has-cta
                @details="navigateToDetails(variant.id)"
              />
            </li>
            <template v-if="isSelectedSubscriptionQuarterly">
              <li
                v-for="variant in filteredSelectedBoxUpsellVariants"
                :key="variant.id"
                class="w-45 max-w-full"
              >
                <CustomizeVariantCard
                  :variant="variant"
                  display-only
                  has-cta
                  @details="navigateToDetails(variant.id)"
                />
              </li>
            </template>
          </ul>
        </BaseContainer>
      </template>

      <Portal to="modal">
        <UpgradeConfirmation
          v-if="confirmationModalOpen"
          :submitting="submitting"
          :error="error"
          @upgrade="upgradeImmediately"
          @dismiss="dismiss"
        />
        <UpgradeSuccess v-else-if="upgradeSuccessfulVisible" />
        <RouterView v-else />
      </Portal>

      <Portal to="fixedBottom">
        <BaseTransitionFixedBottom>
          <div v-if="!navigating && isReviewAndConfirm" class="lg:hidden bg-primary">
            <BaseButton
              :disabled="placingOrder || calculatingLineItems"
              color="primary-alt"
              @click="submit"
            >
              {{ buttonCopy }}
            </BaseButton>
          </div>
        </BaseTransitionFixedBottom>
      </Portal>

      <!-- Loading spinner -->
      <BaseSpinner v-if="placingOrder" overlay="fixed" overlay-color="dawn-lt3" class="z-10" />
    </div>
  </div>
</template>

<style lang="postcss" scoped>
  /* purgecss start ignore */
  .review-container {
    @screen lg {
      max-width: 944px;
    }
  }
</style>
