<script>
  import { get } from 'vuex-pathify'
  import LogoChoicePlus from '@/images/logos/LogoChoicePlus.svg'
  import screen from '@/helpers/screen'

  export default {
    components: {
      LogoChoicePlus,
    },
    props: {
      /** An object with the variant properties. */
      variant: {
        type: Object,
        default: undefined,
      },
      /** The choice step this variant belongs to (appplicable for box choice, NOT choice+). */
      choiceStep: {
        type: Number,
        default: undefined,
      },
      /** What the CTA for this card should be (appplicable for box choice, NOT choice+). */
      cta: {
        type: String,
        default: 'View',
        validator: (value) => ['View', 'Edit'].includes(value),
      },
      /** Whether this choice was selected by Alltrue (appplicable for box choice, NOT choice+). */
      selectedByAlltrue: {
        type: Boolean,
        default: false,
      },
      /** Whether the layout should be horizontal or vertical. */
      layout: {
        type: String,
        default: 'horizontal',
        validator: (value) => ['horizontal', 'vertical'].includes(value),
      },
      /** Whether this view is for Choice+. */
      isChoicePlusView: {
        type: Boolean,
        default: false,
      },
      /** Whether there should be a bottom gray border below variant brand, title and CTA / Customize button. */
      hasBottomBorder: {
        type: Boolean,
        default: false,
      },
      /** Whether there is a button to add/remove Choice+ items (appplicable for choice+, NOT box choice). */
      hasChoicePlusButton: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...get('config', ['currentBox']),
      ...get('feature', ['flags']),
      ...get('customize', [
        'getChoiceStepColor',
        'isVariantPurchased',
        'choiceChooseForMeVariant',
        'savedChooseForMeVariantIds',
        'isMysteryVariant',
        'mysteryChoiceImage',
      ]),
      ...get('choicePlusCart', ['isVariantInCart']),
      screen,
      isChoiceEmpty() {
        return !this.variant || this.isChooseForMeVariantVisible
      },
      color() {
        return this.choiceStep ? this.getChoiceStepColor(this.choiceStep - 1) : 'dawn'
      },
      isVariantInStock() {
        return this.variant ? this.variant.hasUnlimitedStock || this.variant.stock > 0 : false
      },
      isPurchased() {
        return this.variant ? this.isVariantPurchased(this.variant.id) : false
      },
      isChooseForMeEnabled() {
        return this.flags['choose-for-me'] ?? false
      },
      chooseForMeVariant() {
        return this.choiceChooseForMeVariant(this.choiceStep)
      },
      isChooseForMeVariantVisible() {
        return (
          this.isChooseForMeEnabled &&
          !this.variant &&
          this.savedChooseForMeVariantIds.includes(this.chooseForMeVariant.id)
        )
      },
      variantToDisplay() {
        return this.isChooseForMeVariantVisible ? this.chooseForMeVariant : this.variant
      },
      variantCardImageToDisplay() {
        return this.isMysteryVariant(this.variant)
          ? this.mysteryChoiceImage
          : this.variantToDisplay?.previewCardImages[0]
      },
      variantCardImageBg() {
        const currentSeasonBgColor = this.currentBox?.season?.toLowerCase()
        return this.isMysteryVariant(this.variant) ? `bg-${currentSeasonBgColor}` : undefined
      },
    },
    methods: {
      emitEvent() {
        switch (this.cta) {
          case 'Edit':
            return this.$emit('edit')
          default:
            return this.$emit('details')
        }
      },
    },
  }
</script>

<template>
  <div class="w-full">
    <div
      :class="`${layout === 'horizontal' ? 'grid grid-layout gap-x-4' : 'flex flex-col h-full'}`"
    >
      <div>
        <!-- Choice step tag sitting on top of image -->
        <!-- for purgecss: bg-winter bg-fall bg-spring bg-primary bg-sunshine bg-summer bg-dawn -->
        <div
          v-if="choiceStep && !isChoicePlusView"
          :class="`absolute top-0 z-10 p-1 bg-${color} w-full text-3xs font-medium leading-none`"
        >
          <div :class="`${layout === 'horizontal' ? 'text-center' : ''}`">
            Choice Product {{ choiceStep }}
          </div>
        </div>
        <!-- Choice+ logo -->
        <div
          v-if="isChoicePlusView"
          :class="`z-10 w-12 absolute top-0 bg-dawn p-1 rounded-full ${
            layout === 'horizontal' ? 'ml-2 mt-2' : 'ml-3 mt-3'
          }`"
        >
          <BaseIcon class="text-primary">
            <LogoChoicePlus />
          </BaseIcon>
        </div>
        <!-- Choose For Me image -->
        <template v-if="isChooseForMeVariantVisible">
          <div class="bg-dawn-lt3">
            <a
              tabindex="0"
              class="cursor-pointer"
              @keydown.enter="$emit('details')"
              @click="$emit('details')"
            >
              <BaseImage
                :aspect-ratio="1"
                responsive="sm"
                background-transparent
                :src="variantCardImageToDisplay"
                :alt="variantToDisplay.productTitle ? variantToDisplay.productTitle : ''"
                :class="variantCardImageBg"
                :sizes="{ default: '50vw', md: '25vw', lg: '20vw' }"
              />
            </a>
          </div>
        </template>
        <!-- No image if choice is empty -->
        <div
          v-else-if="isChoiceEmpty"
          class="bg-gray-opacity5 w-full flex items-center justify-center text-primary text-center"
        >
          <div class="w-full" style="padding-top: 100%">
            <div
              class="absolute mt-3 font-medium text-3xs w-full"
              style="top: 50%; left: 50%; transform: translate(-50%, -50%)"
            >
              Select a Product
            </div>
          </div>
        </div>
        <!-- Variant image -->
        <template v-else-if="!isChoiceEmpty">
          <div class="bg-dawn-lt3">
            <a
              tabindex="0"
              class="cursor-pointer"
              @keydown.enter="$emit('details')"
              @click="$emit('details')"
            >
              <!-- for purgecss: bg-fall bg-summer bg-spring bg-winter -->
              <div :class="isMysteryVariant(variant) ? `p-4 ${variantCardImageBg}` : ''">
                <BaseImage
                  :aspect-ratio="1"
                  responsive="sm"
                  background-transparent
                  :src="variantCardImageToDisplay"
                  :alt="variantToDisplay.productTitle ? variantToDisplay.productTitle : ''"
                  :class="variantCardImageBg"
                  :sizes="{ default: '50vw', md: '25vw', lg: '20vw' }"
                />
              </div>
            </a>

            <!-- 'Selected by Alltrue' text for vertical layout -->
            <div
              v-if="selectedByAlltrue && layout === 'vertical'"
              class="absolute bottom-0 left-0 bg-dawn ml-1 mb-1 px-2 py-1 opacity-80"
              style="border-radius: 21px"
            >
              <span class="font-medium text-4xs"> Selected by Alltrue </span>
            </div>
          </div>
        </template>
      </div>
      <!-- Variant brand and title (+ price if card is for Choice+)  -->
      <div :class="`flex ${isChoiceEmpty ? 'justify-center' : 'justify-between'}`">
        <!-- Choose For Me -->
        <div
          v-if="isChooseForMeVariantVisible"
          :class="`flex flex-col justify-evenly w-full ${
            hasBottomBorder ? 'border-b border-dawn-dk4' : ''
          }`"
        >
          <BaseLink
            :class="`
            ${layout === 'horizontal' ? '' : 'mt-10px mb-5 overflow-hidden ellipsis line-clamp-2'}
            ${layout === 'vertical' && !isChoicePlusView ? 'h-8' : ''}
            text-xs leading-tight hover:underline`"
            @click="$emit('details')"
          >
            <span class="font-semibold uppercase">{{ variantToDisplay.brand }}</span>
            <br />
            <div>
              {{ variantToDisplay.productTitle }}
            </div>
          </BaseLink>
          <!-- CTA for box choice variants ('View' or 'Edit') -->
          <div class="leading-none font-medium flex justify-between items-center">
            <BaseLink
              :tabindex="-1"
              :class="`${layout === 'horizontal' ? 'text-2xs' : 'text-4xs'}`"
              @click="emitEvent"
            >
              {{ cta }}
              <BaseIcon :size="2" class="inline-block align-middle mb-2px">
                <IconChevronRightThick />
              </BaseIcon>
            </BaseLink>
          </div>
        </div>
        <!-- Main Customize Card -->
        <div
          v-else-if="!isChoiceEmpty"
          :class="`flex flex-col justify-evenly w-full ${
            hasBottomBorder ? 'border-b border-dawn-dk4' : ''
          }`"
        >
          <BaseLink
            :class="`
            ${layout === 'horizontal' ? '' : 'mt-10px mb-5 overflow-hidden ellipsis line-clamp-2'}
            ${layout === 'vertical' && !isChoicePlusView ? 'h-8' : ''}
            text-xs leading-tight hover:underline`"
            @click="$emit('details')"
          >
            <span class="font-semibold uppercase">{{ variantToDisplay.brand }}</span>
            <br />
            <div :class="`${isChoicePlusView ? 'truncate w-full' : ''}`">
              {{ variantToDisplay.productTitle }}
            </div>
            <div
              v-if="
                isChoicePlusView &&
                (isPurchased || (!hasChoicePlusButton && isVariantInCart(variantToDisplay.id)))
              "
              >{{ $formatPrice(variantToDisplay.price) }}</div
            >
          </BaseLink>
          <!-- Text below the brand and title such as CTA, 'Selected by Alltrue' and 'Purchased' -->
          <div class="leading-none font-medium flex justify-between items-center">
            <template v-if="isChoicePlusView && !isPurchased">
              <template v-if="hasChoicePlusButton">
                <div v-if="!isVariantInStock" class="text-2xs italic">
                  Not eligible for Choice+
                </div>
                <BaseButtonPill
                  v-else-if="isVariantInCart(variantToDisplay.id)"
                  height="tall"
                  color="summer"
                  :full-width="!screen.md"
                  class="w-full md:w-auto"
                  @click="$emit('remove')"
                >
                  <div class="font-semibold text-2xs flex items-center px-2">
                    <div class="pt-2px">
                      <BaseIcon :size="3" class="inline-block align-middle mb-2px mr-2px">
                        <IconCheckmarkWide />
                      </BaseIcon>
                      <span> Added </span>
                    </div>
                    <span class="font-light text-xl mx-2">&#124;</span>
                    <span class="pt-2px">{{ $formatPrice(variantToDisplay.price) }}</span>
                  </div>
                </BaseButtonPill>
                <BaseButtonPill
                  v-else
                  height="tall"
                  color="black"
                  outline-only
                  :full-width="!screen.md"
                  class="w-full md:w-auto"
                  @click="$emit('addToCart')"
                >
                  <div class="xs:px-2 text-2xs flex items-center">
                    <BaseIcon class="text-primary w-11">
                      <LogoChoicePlus />
                    </BaseIcon>
                    <span class="font-light text-xl mx-2">&#124;</span>
                    <span class="font-medium pt-1"
                      >Add - {{ $formatPrice(variantToDisplay.price) }}</span
                    >
                  </div>
                </BaseButtonPill>
              </template>
              <!-- CTA for Choice+ cart variants ('View' and 'Remove') -->
              <div
                v-else-if="isChoicePlusView"
                :class="`${layout === 'horizontal' ? 'text-2xs' : 'text-4xs'}`"
              >
                <BaseLink tabindex="0" @click="$emit('details')"> View </BaseLink>
                <span class="mx-1">&#124;</span>
                <BaseLink tabindex="0" @click="$emit('remove')"> Remove </BaseLink>
              </div>
            </template>
            <!-- CTA for box choice variants ('View' or 'Edit') -->
            <BaseLink
              v-else
              :tabindex="isChoicePlusView ? 0 : -1"
              :class="`${layout === 'horizontal' ? 'text-2xs' : 'text-4xs'}`"
              @click="emitEvent"
            >
              {{ cta }}
              <BaseIcon :size="2" class="inline-block align-middle mb-2px">
                <IconChevronRightThick />
              </BaseIcon>
            </BaseLink>
            <!-- 'Selected by Alltrue' text for horizontal layout -->
            <span v-if="selectedByAlltrue && layout === 'horizontal'" class="text-4xs">
              Selected by Alltrue
            </span>
            <!-- 'Purchased' text for Choice+ purchased variants -->
            <div v-if="isChoicePlusView && isPurchased" class="text-4xs">
              <BaseIcon class="inline-block align-middle text-black w-10px mb-2px">
                <IconCheckmarkRounded />
              </BaseIcon>
              <span class="italic"> Purchased </span>
            </div>
          </div>
        </div>
        <!-- 'Customize' button for empty box choice variants -->
        <BaseButtonPill
          v-else
          color="primary"
          height="tall"
          :class="`mt-14px flex justify-center items-center ${
            hasBottomBorder ? 'border-b border-gray-active w-full' : ''
          }`"
          @click="$emit('edit')"
        >
          <span class="font-medium text-sm px-3">Customize</span>
        </BaseButtonPill>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
  .grid-layout {
    grid-template-columns: 1.5fr 3fr;

    @screen md {
      grid-template-columns: 1.3fr 4fr;
    }
  }

  .bg-primary {
    @apply text-dawn;
  }
</style>
