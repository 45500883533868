<script>
  import { get } from 'vuex-pathify'
  import screen from '@/helpers/screen'

  export default {
    props: {
      address: {
        type: Object,
        required: true,
      },
      trackingUrl: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        showAddressToolTipModal: false,
      }
    },
    computed: {
      screen,
      ...get('config', ['currentSeason']),
      ...get('customize', [
        'selectedSubscription',
        'isSelectedSubscriptionCustomizable',
        'filteredSelectedPurchasedChoicePlusVariants',
      ]),
      ...get('choicePlusCart', ['cartQuantity']),
      userFullName() {
        let fullName = ''
        const firstName = this.address?.firstName
        const lastName = this.address?.lastName
        if (firstName) {
          fullName += firstName
        }
        if (lastName) {
          fullName += ` ${lastName}`
        }
        return fullName
      },
      purchasedChoicePlus() {
        return this.filteredSelectedPurchasedChoicePlusVariants?.length
      },
    },
  }
</script>

<template>
  <div :class="{ 'border-1 border-black p-4 mt-10px': screen.lg }">
    <!-- Mobile -->
    <BaseAccordion v-if="!screen.lg" icon="minus">
      <template v-slot:buttonLabel="slotProps">
        <p>
          <span class="font-medium text-base text-black capitalize">Shipping</span>
          <span class="font-medium text-base text-black lowercase mr-1">to:</span>
          <span v-if="!slotProps.expanded" class="capitalize font-normal text-base">
            {{ userFullName }}
          </span>
        </p>
      </template>
      <div class="mt-4" style="line-height: 20px">
        <p v-if="userFullName" class="text-base font-medium">{{ userFullName }}</p>
        <BaseAddress
          :address="address"
          name-font-weight="font-normal"
          :compact-address="!screen.md"
          class="text-sm leading-snug-tight"
        >
          <BaseIcon
            label="Information"
            type="button"
            :size="3"
            class="inline-block transform translate-y-2px"
            @click="showAddressToolTipModal = true"
          >
            <IconInfoAlertOutline class="text-black" />
          </BaseIcon>
        </BaseAddress>
        <template v-if="trackingUrl">
          <BaseDivider class="my-4" color="black" />
          <div class="text-2xs font-medium leading-close text-center">
            <BaseLink class="underline inline-block" :href="trackingUrl" target="_blank">
              View Box Tracking Info
            </BaseLink>
            <template v-if="purchasedChoicePlus">
              <span class="mx-1">|</span>
              <BaseLink class="underline inline-block" :to="{ name: 'AccountProfileOrders' }">
                View Choice+ Order
              </BaseLink>
            </template>
          </div>
        </template>
      </div>
    </BaseAccordion>

    <!-- Desktop -->
    <template v-if="screen.lg">
      <div class="mb-4 flex justify-between content-center text-base leading-snug-tight">
        <span class="inline-block font-medium">Shipping to:</span>
        <span class="inline-block">
          <BaseIcon
            label="Information"
            type="button"
            :size="4"
            @click="showAddressToolTipModal = true"
          >
            <IconInfoAlertOutline class="text-black icon-info-alert" />
          </BaseIcon>
        </span>
      </div>
      <p v-if="userFullName" class="text-base font-medium leading-close">{{ userFullName }}</p>
      <BaseAddress :address="address" class="text-sm leading-close" />
      <template v-if="trackingUrl">
        <BaseDivider class="mt-4 mb-3" color="black" />
        <div class="text-3xs font-medium leading-close text-center">
          <BaseLink class="underline inline-block" :href="trackingUrl" target="_blank">
            View Box Tracking Info
          </BaseLink>
          <template v-if="purchasedChoicePlus">
            <span class="mx-1">|</span>
            <BaseLink class="underline inline-block" :to="{ name: 'AccountProfileOrders' }">
              View Choice+ Order
            </BaseLink>
          </template>
        </div>
      </template>
    </template>

    <!-- Address Tool Tip Modal -->
    <BaseModal
      v-if="showAddressToolTipModal"
      :open="showAddressToolTipModal"
      size="xs"
      bg-color="dawn-lt3"
      dismissible
      @dismiss="showAddressToolTipModal = false"
    >
      <p class="font-medium mb-4">Your box will be shipped to:</p>

      <p v-if="userFullName" class="font-medium leading-close">{{ userFullName }}</p>
      <BaseAddress :address="address" class="text-sm leading-close" />
      <BaseDivider class="my-4" color="black" />
      <p class="text-gray-opacity68 text-xs leading-snug-tight">
        Note: If you need to update your shipping address, please update in your account settings
        under the Memberships tab.
      </p>
    </BaseModal>
  </div>
</template>
