<script>
  import { get, call } from 'vuex-pathify'
  import LogoChoicePlus from '@/images/logos/LogoChoicePlus.svg'

  export default {
    components: {
      LogoChoicePlus,
    },
    props: {
      /** An object with the variant properties. */
      variant: {
        type: Object,
        required: true,
      },
      /** Whether the user selected this card as their choice.  */
      chosen: {
        type: Boolean,
        default: false,
      },
      /** Whether a choice has been selected, but does not have to be this specific card.  */
      otherChoiceChosen: {
        type: Boolean,
        default: false,
      },
      /** Whether this card is for display purposes only (e.g., in the subscription upsell screen).  */
      displayOnly: {
        type: Boolean,
        default: false,
      },
      /** Shipping notice */
      shippingNotice: {
        type: String,
        default: null,
      },
      /** Whether this card has choice options including selecting choice and adding to choicePlus cart (can be plain text or button).  */
      hasCta: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        showRemoveChoiceModal: false,
        showShippingNotice: false,
        newSelStatus: null,
      }
    },
    computed: {
      ...get('config', ['currentBox']),
      ...get('choicePlusCart', ['isVariantInCart', 'placingOrder']),
      ...get('customize', [
        'filteredSelectedPurchasedChoicePlusVariantIds',
        'choiceChooseForMeVariant',
        'isMysteryVariant',
        'mysteryChoiceImage',
      ]),
      eligibleForChoicePlus() {
        return this.variant?.hasUnlimitedStock || this.variant?.stock > 0
      },
      isPurchased() {
        return this.filteredSelectedPurchasedChoicePlusVariantIds?.includes(this.variant.id)
      },
      isChooseForMeVariant() {
        return this.variant.id === this.choiceChooseForMeVariant().id
      },
      variantCardImageToDisplay() {
        return this.isMysteryVariant(this.variant)
          ? this.mysteryChoiceImage
          : this.variant?.previewCardImages[0]
      },
      variantCardImageBg() {
        const currentSeasonBgColor = this.currentBox?.season?.toLowerCase()
        return this.isMysteryVariant(this.variant) ? `bg-${currentSeasonBgColor}` : undefined
      },
    },
    created() {
      this.selStatus = this.variant.selectionStatus
      this.newSelStatus = ( this.chosen ) ? 'active' : this.variant.selectionStatus
    },
    methods: {
      ...call('choicePlusCart', ['addToCart', 'removeCartItem']),
      /**
       * Checks if the unselected product is available via selectionStatus or stock. Shows confirmation modal if variant is unavailable.
       */
      unselectVariant() {
        if (
          this.newSelStatus !== 'active' ||
          (!this.eligibleForChoicePlus &&
            !this.isChooseForMeVariant &&
            !this.isMysteryVariant(this.variant))
        ) {
          this.showRemoveChoiceModal = true
        } else {
          this.$emit('choose', null)
        }
      },
      removeVariant() {
        this.showRemoveChoiceModal = false
        this.$emit('choose', null)
      },
    },
  }
</script>

<template>
  <div>
    <div
      role="button"
      :tabindex="0"
      :class="`cursor-pointer bg-white shadow-md ${chosen ? 'border border-black' : ''} ${
        !chosen && isVariantInCart(variant.id) && $route.name !== 'BoxArchive ' && hasCta
          ? 'border border-summer'
          : ''
      }`"
      @keydown.enter="$emit('details', variant.id)"
      @click="$emit('details', variant.id)"
    >
      <!-- Highlight chosen option with border -->
      <div
        v-if="chosen"
        class="z-10 bg-primary absolute top-0 h-8 w-full flex items-center justify-center text-white text-2xs"
      >
        Your Choice
        <!-- Unselect X icon -->
        <button label="unselect" class="absolute right-0 w-8 h-8" @click.stop="unselectVariant">
          <BaseIcon label="unselect" class="float-right mr-2 w-14px h-14px">
            <IconXThick />
          </BaseIcon>
        </button>
      </div>
      <div
        v-if="
          (isVariantInCart(variant.id) && $route.name !== 'BoxArchive' && hasCta) || isPurchased
        "
        :class="`z-10 bg-dawn p-1 rounded-full absolute top-0 ml-3 ${chosen ? 'mt-11' : 'mt-3'}`"
      >
        <BaseIcon class="text-primary">
          <LogoChoicePlus class="w-12" />
        </BaseIcon>
      </div>
      <div :class="`${hasCta ? 'h-70' : 'h-full'} flex flex-col`">
        <!-- for purgecss: bg-fall bg-summer bg-spring bg-winter -->
        <div :class="isMysteryVariant(variant) ? `p-4 ${variantCardImageBg}` : ''">
          <BaseImage
            :aspect-ratio="1"
            :src="variantCardImageToDisplay"
            :alt="variant.productTitle ? variant.productTitle : ''"
            :class="variantCardImageBg"
            responsive="xs"
          />
        </div>
        <div class="flex flex-col flex-grow items-center justify-evenly px-2">
          <!-- Variant brand and title -->
          <div
            :class="`h-8 ${
              hasCta ? '' : 'my-4'
            } text-xs text-center overflow-hidden ellipsis line-clamp-2`"
            style="line-height: 1.3"
          >
            <template v-if="!isChooseForMeVariant">
              {{ variant.brand }} <span v-if="variant.brand && variant.productTitle">&#183;</span>
            </template>
            {{ variant.productTitle }}
          </div>
          <!-- Different choice options including selecting choice and adding to choicePlus cart (can be plain text or button) -->
          <div v-if="hasCta" class="flex items-center">
            <template v-if="newSelStatus === 'displayOnly'">
              <template v-if="$route.name !== 'CustomizeUpgrade'">
                <div
                  v-if="$route.name === 'CustomizeSummary'"
                  class="nonSelectableCopy text-summer"
                >
                  Upgrade to customize
                </div>
                <div v-else class="nonSelectableCopy text-gray-opacity68"> Sold out </div>
              </template>
              <div v-else-if="$route.name === 'CustomizeUpgrade'"
                class="nonSelectableCopy text-summer"
              >
                Upgrade to customize
              </div>
            </template>
            <BaseButtonPill
              v-else-if="
                !chosen &&
                (!otherChoiceChosen || isChooseForMeVariant) &&
                $route.name !== 'CustomizeSummary'
              "
              height="tall"
              color="primary"
              outline-only
              data-cy="select-button"
              @click.stop="$emit('choose', variant.id)"
            >
              <div
                v-if="isChooseForMeVariant"
                class="px-3 font-semibold text-sm whitespace-no-wrap"
              >
                Choose for me
              </div>
              <span v-else class="px-3 font-semibold text-sm"> Select </span>
            </BaseButtonPill>
            <div v-else-if="isPurchased">
              <BaseIcon :size="3" class="inline-block align-middle text-black mb-1px">
                <IconCheckmarkRounded />
              </BaseIcon>
              <span class="font-medium text-3xs italic"> Purchased </span>
            </div>
            <template v-else>
              <div
                v-if="chosen && (isChooseForMeVariant || isMysteryVariant(variant))"
                class="nonSelectableCopy text-gray-opacity68"
              >
                Selected
              </div>
              <div v-else-if="!eligibleForChoicePlus" class="nonSelectableCopy text-gray-opacity68">
                Not eligible for Choice+
              </div>
              <BaseButtonPill
                v-else-if="isVariantInCart(variant.id)"
                :disabled="placingOrder"
                height="tall"
                color="summer"
                @click.stop="removeCartItem({ variantId: variant.id })"
              >
                <template v-slot:icon><IconCheckmark /></template>
                <span class="font-semibold text-2xs pr-2"> Added </span>
              </BaseButtonPill>
              <div v-else-if="chosen && selStatus === 'displayOnly' && newSelStatus === 'active'" class="nonSelectableCopy text-gray-opacity68"> Selected </div>
              <div v-else-if="selStatus === 'displayOnly' && newSelStatus === 'active'" class="nonSelectableCopy text-gray-opacity68"> Unavailable </div>
              <BaseButtonPill
                v-else
                :disabled="placingOrder"
                height="tall"
                color="primary"
                outline-only
                @click.stop="addToCart({ variantId: variant.id })"
              >
                <template v-if="chosen && isMysteryVariant(variant)"> Selected </template>
                <div v-else class="xs:px-2 font-bold text-2xs flex items-center">
                  <BaseIcon>
                    <LogoChoicePlus class="w-12 pb-2px md:pb-1" />
                  </BaseIcon>
                  <span class="font-light text-xl mx-2px">&#124;</span>
                  <span class="pt-1px">Add - {{ $formatPrice(variant.price) }}</span>
                </div>
              </BaseButtonPill>
            </template>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-3 flex justify-center leading-none font-medium text-2xs">
      <BaseLink :tabindex="0" @click="$emit('details', variant.id)">
        <template v-if="isChooseForMeVariant || isMysteryVariant(variant)">
          Learn More
          <BaseIcon
            type="icon"
            label="learn more"
            :size="3"
            class="ml-1 -mt-2px inline-block align-middle"
          >
            <IconInfoAlertOutline />
          </BaseIcon>
        </template>
        <template v-else>View Details</template>
      </BaseLink>
    </div>

    <BaseDivider v-if="shippingNotice" class="my-2" color="dawn-dk2" />

    <div
      v-if="shippingNotice"
      class="border-dawn border-b-1 cursor-pointer flex flex-row justify-center items-center content-center"
      @click="showShippingNotice = true"
    >
      <BaseIcon :size="3" label="shipping notice" class="inline-block text-summer">
        <IconInfoAlert />
      </BaseIcon>
      <span class="ml-1 text-primary text-12px">Shipping Notice</span>
    </div>

    <BaseModalError
      v-if="showShippingNotice"
      :open="true"
      hide-nav-on-mobile
      icon="IconInfoAlert"
      icon-color="summer"
      @dismiss="showShippingNotice = false"
    >
      <template v-slot:header><span class="ml-1">Shipping Notice</span></template>
      {{ shippingNotice }}
    </BaseModalError>

    <BaseModal
      v-if="showRemoveChoiceModal"
      :open="showRemoveChoiceModal"
      size="xs"
      bg-color="dawn-lt3"
      class="text-center"
    >
      <BaseHeading size="h2" class="mb-6">Remove Choice?</BaseHeading>
      <p class="mb-6"
        >Are you sure you want to change your primary choice? This product is not available as a
        Choice+ selection - so you will not be able to include it in your box if it is not your
        primary choice.</p
      >

      <div class="sm:w-1/2 mx-auto">
        <BaseButton class="mb-4" outline-only @click="removeVariant">Remove</BaseButton>
        <BaseButton @click="showRemoveChoiceModal = false">Cancel</BaseButton>
      </div>
    </BaseModal>
  </div>
</template>

<style lang="postcss">
  .nonSelectableCopy {
    @apply flex justify-center font-semibold text-2xs text-center italic;
  }
</style>
