<script>
  import LogoChoicePlusCheckout from '@/images/logos/LogoChoicePlusCheckout.svg'

  /**
   * A card representing a cart item, including the main image and information about the variant such as its brand, title, price, etc.
   */
  export default {
    components: {
      LogoChoicePlusCheckout,
    },
    props: {
      /** Data about the line item { variant, quantity, removed }. */
      lineItem: {
        type: Object,
        required: true,
      },
      /** Whether this line item has already been purchased. */
      isPurchased: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      // if purchased, the variant object should be passed into the lineItem prop, which has the same properties as lineItem.variant
      variant() {
        return this.lineItem.variant
      },
      removed() {
        return this.lineItem.removed
      },
    },
    methods: {
      removeCartItem() {
        this.$emit('removeCartItem', { variantId: this.variant.id })
      },
      toggleRemoveItem() {
        this.$emit('toggleRemoveItem', { variantId: this.variant.id })
      },
      imageAlt(title) {
        return title ?? ''
      },
    },
  }
</script>

<template>
  <!--------------- Item removed --------------->
  <div v-if="removed" class="py-4">
    <div class="grid grid-cols-cart-item-mobile md:grid-cols-cart-item bg-gray-lightest p-3">
      <div class="w-12">
        <BaseImage
          :src="variant.previewCardImages[0]"
          :alt="imageAlt(variant.productTitle)"
          :aspect-ratio="1"
        />
      </div>
      <div class="md:col-span-2 flex items-center justify-between">
        <div class="text-gray-darker text-sm">
          Item Removed.
          <BaseLinkStyled inline @click="toggleRemoveItem">Undo</BaseLinkStyled>
        </div>
        <div>
          <BaseIconClickable label="Dismiss" :size="3" :padding="2" @click="removeCartItem">
            <IconX />
          </BaseIconClickable>
        </div>
      </div>
    </div>
  </div>
  <!--------------- Cart item --------------->
  <div v-else class="py-4 grid grid-layout gap-x-5 md:gap-x-7">
    <div>
      <BaseLink @click="$emit('details')">
        <BaseImage
          :src="variant.previewCardImages[0]"
          :alt="imageAlt(variant.productTitle)"
          :aspect-ratio="1"
        />
      </BaseLink>
      <LogoChoicePlusCheckout class="z-10 w-8 absolute bottom-0 right-0 -mr-2 -mb-2" />
    </div>
    <div class="flex justify-between md:py-2">
      <div class="flex flex-col justify-between w-full">
        <div>
          <div class="font-semibold text-xs uppercase leading-snug">
            {{ variant.brand }}
          </div>
          <div class="text-xs overflow-hidden ellipsis line-clamp-2 leading-tight">
            {{ variant.productTitle }}
          </div>
        </div>
        <div class="flex justify-between items-end">
          <div v-if="isPurchased">
            <BaseIcon :size="3" class="inline-block align-middle text-black mb-2px">
              <IconCheckmarkRounded />
            </BaseIcon>
            <span class="font-medium text-2xs italic"> Purchased </span>
          </div>
          <BaseLinkStyled
            v-else
            font-size="2xs"
            font-weight="medium"
            inline
            color="black"
            @click="toggleRemoveItem"
          >
            Remove
          </BaseLinkStyled>
          <!-- Price on the right  -->
          <span class="ml-10 font-medium text-xs text-primary">
            {{ $formatPrice(variant.price) }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
  .grid-layout {
    grid-template-columns: 1.1fr 3fr;

    @screen md {
      grid-template-columns: 1fr 4.5fr;
    }
  }
</style>
